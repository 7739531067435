/*
 ** This file contains all the logic needed for the readings worker to run
 */
import { lineString, buffer, bbox, simplify } from '@turf/turf';
import { MiscUtils } from 'aegion_common_utilities';

import ReadingsUtils from '../util/readings';

const { SortDirection } = ReadingsUtils;

// Create a flattened record of readings through all of the data - sort by stationId
const _transformReading = (item, dat, datIndex, gpsIndex, startStn) => {
	const {
		gpsreadings: [{ StationId: firstStationId }]
	} = dat;
	const stationId = parseFloat(
		dat.reverse
			? startStn - item.StationId + firstStationId
			: startStn + item.StationId
	);

	const { prevGap } = item;
	const newPrevGap = prevGap !== undefined ? prevGap : '';

	return MiscUtils.shallowCopy(item, {
		stationId,
		gpsIndex,
		datIndex,
		color: dat.color,
		prevGap: newPrevGap
	});
};

export const getStartStn = dat => {
	if (dat.reverse) {
		if (dat.endStn !== undefined) {
			return dat.endStn;
		}
		return dat.calcEnd;
	}
	if (dat.fileName.toLowerCase().indexOf('.csv') > -1) {
		const { csvVersion = 1 } = dat;
		if (
			csvVersion === 1 &&
			dat.startStn !== undefined &&
			dat.endStn !== undefined
		) {
			return dat.startStn - dat.calcStart;
		}
		if (csvVersion === 2) {
			if (dat.startStn !== undefined) {
				return dat.startStn;
			}

			return dat.calcStart;
		}
		return 0;
	}

	if (dat.startStn !== undefined) {
		return dat.startStn;
	}

	return dat.calcStart;
};

// transforms each reading object to something that can be digested by the Readings.jsx component
const _transformReadings = (dat, datIndex) => {
	const { gpsreadings } = dat;
	if (!gpsreadings) {
		return [];
	}
	const startStn = getStartStn(dat);
	return gpsreadings.reduce((acc, gpsReading, gpsIndex) => {
		const transformed = _transformReading(
			gpsReading,
			dat,
			datIndex,
			gpsIndex,
			startStn
		);
		acc.push(transformed);
		return acc;
	}, []);
};

// We do tranform readings (above) for each dat file
const _reduceGPSReadings = (acc, dat, datIndex) => {
	const result = _transformReadings(dat, datIndex);

	return acc.concat(result);
};

const _findInterval = convertedReadings => {
	let interval;
	if (convertedReadings.length > 2) {
		interval = convertedReadings[1].stationId - convertedReadings[0].stationId;
	}
	return interval;
};

const _findIndex = (convertedReadings, stnId) => {
	return convertedReadings.findIndex(r => stnId === r.stationId); // stationId takes care of various dat stationing.
};

const _editSkipWithFieldChange = (skipReading, fieldName, fieldValue) => {
	return {
		...skipReading,
		[fieldName]: fieldValue
	};
};

const _addSkipFirstLastSegment = (
	tempSkipReadingsWithAttributes,
	convertedReadings
) => {
	let idx;
	const skipReadings = [];

	tempSkipReadingsWithAttributes.forEach(skipReadingAttri => {
		const { skipReading, previousSkipReading } = skipReadingAttri;

		if (skipReading.isStartSkip || skipReading.isEndSkip) {
			idx = _findIndex(convertedReadings, skipReading.stationId);
			if (skipReading.isStartSkip) {
				// add previous station id's reading
				skipReadings.push(convertedReadings[idx - 1]);
			} else if (skipReading.isEndSkip) {
				// add next station id's reading
				skipReadings.push(convertedReadings[idx + 1]);
			}
		}
		skipReadings.push(skipReading);

		if (!previousSkipReading) {
			return;
		}
		const prevskipReading = previousSkipReading;
		if (prevskipReading.isEndSkip) {
			idx = _findIndex(convertedReadings, previousSkipReading.stationId);
			skipReadings.push(convertedReadings[idx + 1]);
		}
	});

	return skipReadings.sort((a, b) => {
		if (a.stationId === b.stationId) {
			return a.datIndex - b.datIndex;
		}

		return a.stationId - b.stationId;
	});
};

const _setSkipAttributes = (tempSkipReadings, interval) => {
	return tempSkipReadings.map((skipReading, i, tempSkipReadingsArr) => {
		const previousSkipReading =
			i > 0
				? // &&
				  // tempSkipReadingsArr[i - 1].datIndex === tempSkipReadingsArr[i].datIndex
				  tempSkipReadingsArr[i - 1]
				: undefined;
		const previousReadingValue = previousSkipReading;

		const isBeginningOfNextSection = previousReadingValue
			? Math.abs(skipReading.stationId - previousSkipReading.stationId) >
			  interval
			: false;
		if (i === 0) {
			return {
				skipReading: _editSkipWithFieldChange(skipReading, 'isStartSkip', true),
				previousSkipReading
			};
		}

		if (isBeginningOfNextSection) {
			return {
				skipReading: _editSkipWithFieldChange(skipReading, 'isStartSkip', true),
				previousSkipReading: _editSkipWithFieldChange(
					previousSkipReading,
					'isEndSkip',
					true
				)
			};
		}

		if (i === tempSkipReadings.length - 1) {
			return {
				skipReading: _editSkipWithFieldChange(skipReading, 'isEndSkip', true),
				previousSkipReading
			};
		}

		return { skipReading, previousSkipReading };
	});
};

const _filterReadings = (filter, convertedReadings) => {
	if (!filter) {
		return convertedReadings;
	}

	if (filter.indexOf('*') === 0) {
		// Filter for skips
		if (filter.split('*')[1].toUpperCase() === 'S') {
			return convertedReadings.filter(item => item.code === 4);
		}

		// Filter for if it has a comment
		return convertedReadings.filter(item => item.comments);
	}

	// Filter for gaps greater than
	if (filter.indexOf('>') >= 0 && filter.split('>')[1] !== undefined) {
		return convertedReadings.filter(
			item => item.prevGap >= filter.split('>')[1]
		);
	}
	// Filter for  gaps less than
	if (filter.indexOf('<') >= 0 && filter.split('<')[1] !== undefined) {
		return convertedReadings.filter(
			item => item.prevGap <= filter.split('<')[1]
		);
	}
	// When greater than (>) and less than (<) are not available - search for station or comment
	if (filter.indexOf('>') < 0 && filter.indexOf('<') < 0) {
		return convertedReadings.filter(
			item =>
				`${item.stationId}`.indexOf(filter) >= 0 ||
				(item.comments && item.comments.toLowerCase().indexOf(filter) >= 0)
		);
	}

	return convertedReadings;
};

const _sortData = (readings, sortDirection) => {
	// const sortBy = dats[0].reverse ? 'nextGap' : 'prevGap';
	const sortBy = 'sortGap';

	const lt = sortDirection === SortDirection.ASC ? -1 : 1;
	const gt = sortDirection === SortDirection.ASC ? 1 : -1;
	return readings.slice(0).sort((a, b) => {
		if (a[sortBy] === null) {
			return lt;
		}
		if (b[sortBy] === null) {
			return gt;
		}
		if (a[sortBy] < b[sortBy]) {
			return lt;
		}
		if (a[sortBy] > b[sortBy]) {
			return gt;
		}
		return 0;
	});
};

const _deriveSkipReadings = convertedReadings => {
	const tempSkipReadings = convertedReadings.filter(
		gpsReading => gpsReading.code === 4
	);

	const interval = _findInterval(convertedReadings);
	const tempSkipReadingsWithAttributes = _setSkipAttributes(
		tempSkipReadings,
		interval
	);

	return _addSkipFirstLastSegment(
		tempSkipReadingsWithAttributes,
		convertedReadings
	);
};

const _convertData = dats => {
	// Zip all the dats together
	const convertedReadingsData = dats.reduce(_reduceGPSReadings, []);
	// Finally, we sort by station id
	convertedReadingsData.sort((a, b) => {
		return a.stationId - b.stationId;
	});

	return convertedReadingsData;
};

/* Decouple all logic from exports so they are easily itemized */
const deriveSkipReadings = convertedReadings => {
	return _deriveSkipReadings(convertedReadings);
};

const getUpdateFilteredReadings = (filter, convertedReadings) => {
	return _filterReadings(filter, convertedReadings);
};

const convertData = dats => {
	return _convertData(dats);
};

const sortData = (readings, sortDirection, dats) => {
	readings.map(reading => {
		if (dats[reading.datIndex].reverse) {
			reading.sortGap = reading.nextGap ? reading.nextGap : '';
		} else {
			reading.sortGap = reading.prevGap ? reading.prevGap : '';
		}
		return reading;
	});
	return _sortData(readings, sortDirection);
};

/* const getMin = (n1, n2) => {
	if (Number.isNaN(n1)) {
		return n2;
	}
	if (Number.isNaN(n2)) {
		return n1;
	}
	if (n1 < n2) {
		return n1;
	}
	return n2;
};
const getMax = (n1, n2) => {
	if (Number.isNaN(n1)) {
		return n2;
	}
	if (Number.isNaN(n2)) {
		return n1;
	}
	if (n1 < n2) {
		return n2;
	}
	return n1;
}; */

const getCoords = readings => {
	const coords = readings.reduce(
		(acc, reading) => {
			const { coordinates, datIndex } = reading;
			const [lat = Number.NaN, lon = Number.NaN] = coordinates;
			acc.line.push([lon, lat]);
			acc.datIndex = datIndex;
			return acc;
		},
		{
			line: [],
			datIndex: -1
		}
	);
	return coords;
};

const getLine = (coords, smoothed = false) => {
	if (!smoothed) {
		return lineString(coords.line);
	}
	const line = simplify(lineString(coords.line), {
		tolerance: 0.00001,
		mutate: true
	});
	return line;
};

const getBounds = readings => {
	const coords = getCoords(readings);
	const line = getLine(coords);
	const box = bbox(line);
	const [minx, miny, maxx, maxy] = box;
	return { minx, miny, maxx, maxy, datIndex: coords.datIndex };
};

const getBoundsByFile = readingsByFile => {
	return readingsByFile.map(readings => {
		return getBounds(readings);
	});
};

const getBuffer = readings => {
	const coords = getCoords(readings);
	const line = getLine(coords, true);
	const polygon = buffer(line, 0.11, {
		units: 'kilometers'
	});
	return {
		...coords,
		polygon
	};
};

const getBuffersByFile = readingsByFile => {
	return readingsByFile.map(readings => {
		return getBuffer(readings);
	});
};

export default {
	deriveSkipReadings,
	getUpdateFilteredReadings,
	convertData,
	sortData,
	getStartStn,
	getBoundsByFile,
	getBuffersByFile,
	getBounds
};
