/* eslint import/no-cycle:0 */

import AWS from 'aws-sdk';

import {
	CognitoUserPool,
	CognitoUserAttribute,
	CognitoUser,
	AuthenticationDetails,
	AuthenticationHelper
} from 'amazon-cognito-identity-js';

import {
	hasAccessTokens,
	hasAccessToken,
	hasRefreshToken,
	getRefreshToken,
	destroyAllTokens,
	saveAccessToken,
	saveIdToken,
	saveRefreshToken
} from '../../../scanline/utils/token';
import ajax from '../../../scanline/utils/ajax';

import getAipUrls, { getRegion } from '../../../config/getAipUrls';
import { cGetSession, cGetVerificationCode } from './mfaCookies';
import login from './login';
import loginCognito, { setNewPassword } from './login-cognito';

const AIP_URLS = getAipUrls();
const REGION = getRegion();

const authenticationHelper = new AuthenticationHelper(AIP_URLS.poolidshort);

const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider({
	apiVersion: '2016-04-18',
	region: REGION
});

const AccountUtil = {
	auth: null,
	passwordVerifier: new RegExp(
		/^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/
	),
	hasTokens() {
		return hasAccessTokens();
	},
	async authienicate(authenticationResult) {
		this._setCookies(authenticationResult, this.rememberMe);
	},

	async password(email, password) {
		const res = await setNewPassword(email, password);
		this.authienicate(res.AuthenticationResult);
		return res;

		/* const params = {
			ChallengeName: 'CUSTOM_CHALLENGE',
			ClientId: AIP_URLS.clientid,
			ChallengeResponses: {
				ANSWER: JSON.stringify({ code: cGetVerificationCode(), password }),
				USERNAME: email
			},
			Session: cGetSession()
		};
		const res = await cognitoidentityserviceprovider
			.respondToAuthChallenge(params)
			.promise();
		this.authienicate(res.AuthenticationResult);
		return res; */
		/* this._ajax(
			'/authchallenge',
			JSON.stringify(params),
			'POST',
			(err, data) => {
				if (data) {
					this._setCookies(data.AuthenticationResult);
				}
				callback(err, data);
			},
			true
		); */
	},
	async login(cred, rememberMe) {
		// const res = await login(cred.email, cred.password);
		// console.log(res);

		const res = await loginCognito(cred.email, cred.password);
		this.rememberMe = rememberMe;
		this.authienicate(res.AuthenticationResult);

		return res;
		/* const params = {
			AuthFlow: 'CUSTOM_AUTH',
			AuthParameters: {
				SRP_A: cred.password,
				USERNAME: cred.email,
				CHALLENGE_NAME: 'SRP_A'
			},
			ClientId: AIP_URLS.clientid
		};

		const res = await cognitoidentityserviceprovider
			.initiateAuth(params)
			.promise();
		if (res && res.AuthenticationResult) {
			this.authienicate(res.AuthenticationResult);
		}

		return res; */
		/* this._ajax(
			'/login',
			JSON.stringify(cred),
			'POST',
			(err, data) => {
				if (data) {
					this.auth = data;
					this._setCookies(data.AuthenticationResult, rememberMe);
				}
				callback(err, data);
			},
			true
		); */
	},
	updateattributes(data, callback) {
		this._ajax('/updateattributes', JSON.stringify(data), 'POST', callback);
	},
	user(callback) {
		if (hasAccessToken()) {
			this.refresh((err, data) => {
				if (data) {
					this._ajax('/user', null, 'GET', callback);
				} else {
					callback(new Error('Not logged in'));
				}
			}).catch(() => {
				callback(new Error('Not logged in'));
			});
		} else {
			this._ajax('/user', null, 'GET', (err, data) => {
				if (data) {
					this.auth = data;
				}
				callback(err, data);
			});
		}
	},
	refresh(callback) {
		if (hasRefreshToken()) {
			return this._ajax(
				'/refresh',
				JSON.stringify({ token: getRefreshToken() }),
				'POST',
				undefined,
				true
			)
				.then(data => {
					if (data) {
						this.auth = data;
						this._setCookies(data.AuthenticationResult);
					}
					if (callback) {
						callback(null, data);
					}
					return data;
				})
				.catch(() => {
					AccountUtil.logoff();
					window.location = '/Account/Login';
				});
		}
		return Promise.reject(new Error('Missing Refresh Token'));
	},
	logoff() {
		destroyAllTokens();
	},
	reset(user, callback) {
		return this._ajax(
			'/reset',
			JSON.stringify({ user }),
			'POST',
			callback,
			true
		);
	},
	verify(data, callback) {
		this._ajax('/verify', JSON.stringify(data), 'POST', callback, true);
	},
	verifycode(data, callback) {
		this._ajax('/verifycode', JSON.stringify(data), 'POST', callback);
	},
	async mfa(email, code) {
		const params = {
			ChallengeName: 'CUSTOM_CHALLENGE',
			ClientId: AIP_URLS.clientid,
			ChallengeResponses: {
				ANSWER: code,
				USERNAME: email
			},
			Session: cGetSession()
		};
		const res = await cognitoidentityserviceprovider
			.respondToAuthChallenge(params)
			.promise();
		if (res && res.AuthenticationResult) {
			this.authienicate(res.AuthenticationResult);
		}
		return res;
	},

	updateSettings(data) {
		return this._ajax('/updatesettings', JSON.stringify(data), 'POST');
	},
	settings(column) {
		return this._ajax(`/settings/${column}`, undefined, 'GET');
	},
	profiles(column) {
		return this._ajax(`/profiles/${column}`, undefined, 'GET');
	},
	profile(column, name) {
		return this._ajax(`/profiles/${column}/${name}`, undefined, 'GET');
	},
	setProfile(column, data) {
		return this._ajax(`/profiles/${column}`, JSON.stringify(data), 'PUT');
	},
	deleteProfile(column, name) {
		return this._ajax(`/profiles/${column}/${name}`, undefined, 'DELETE');
	},
	applications() {
		return this._ajax('/applications', null, 'GET');
	},

	_ajax(url, data, method, callback, isAnonymous) {
		return ajax(url, data, method, callback, isAnonymous, 'account');
	},
	_setCookies(authenticationResult, rememberMe) {
		if (authenticationResult) {
			const {
				accessToken: {
					jwtToken: accessToken = authenticationResult.AccessToken
				} = {},
				idToken: { jwtToken: idToken = authenticationResult.IdToken } = {},
				refreshToken: {
					jwtToken: refreshToken = authenticationResult.RefreshToken
				} = {}
			} = authenticationResult;
			saveAccessToken(accessToken);
			saveIdToken(idToken);
			if (refreshToken) {
				if (rememberMe) {
					const now = new Date();
					let time = now.getTime();
					time += 3600 * 1000 * 24 * 30;
					now.setTime(time);
					saveRefreshToken(refreshToken, now);
				} else {
					saveRefreshToken(refreshToken);
				}
			}
		}
	},
	getUrlParameter(sParam) {
		const sURLVariables = window.location.search.substring(1).split('&');
		let sParameterName;
		let i;

		for (i = 0; i < sURLVariables.length; i += 1) {
			sParameterName = sURLVariables[i].split('=');

			if (sParameterName[0] === sParam) {
				return sParameterName[1] === undefined
					? true
					: decodeURIComponent(sParameterName[1]);
			}
		}
		return null;
	},
	verifyPassword(password, confirmPassword) {
		const isVerified =
			password === confirmPassword && this.passwordVerifier.test(password);
		return isVerified;
	},

	signup(user, password) {
		const params = {
			ClientId: AIP_URLS.clientid,
			Password: password,
			Username: user,
			UserAttributes: [
				{
					Name: 'email',
					Value: user
				}
			]
		};
		return cognitoidentityserviceprovider.signUp(params).promise();
	},

	confirmSignup(user, code) {
		const params = {
			ClientId: AIP_URLS.clientid,
			ConfirmationCode: code,
			Username: user
		};
		return cognitoidentityserviceprovider.confirmSignUp(params).promise();
	}
};

export default AccountUtil;
