// cyient.aip.corrpro.com
const URL = 'https://cyient-api.aip.corrpro.com';

const REGION = 'us-east-2';

const aipurls = {
	admin: 'https://cyient-api.aip.corrpro.com/admin',
	account: 'https://cyient-api.aip.corrpro.com/account',
	assetview: 'https://cyient-api.aip.corrpro.com/assetview',
	assetviewWS: 'wss://cyient-ws.aip.corrpro.com/assetview',
	assetviewCorrelator:
		'https://cyient-api.aip.corrpro.com/assetview-correlator',
	customerusers: 'https://cyient-api.aip.corrpro.com/customer-users',
	cisview: 'https://cyient-api.aip.corrpro.com/cisview',
	fieldline: 'https://cyient-api.aip.corrpro.com/fieldline',
	fieldlinebinary: 'https://cyient-api.aip.corrpro.com/fieldline-binary',
	cisviewextended: 'https://cyient-api.aip.corrpro.com/cisview-extended',
	cisviewreadonly: 'https://cyient-api.aip.corrpro.com/cisview-readonly',
	cisviewedits: `https://cyient-api.aip.corrpro.com/cisview-edits`,
	dashboard: 'https://cyient-api.aip.corrpro.com/dashboard',
	chargeline: 'https://cyient-api.aip.corrpro.com/chargeline',
	chargelinebinary: 'https://cyient-api.aip.corrpro.com/chargeline-binary',
	maps: 'https://cyient-api.aip.corrpro.com/maps',
	scanline: 'https://cyient-api.aip.corrpro.com/scanline',
	scanlineWorkflow: 'https://cyient-api.aip.corrpro.com/scanline-workflow',
	scanlineReport: 'https://cyient-api.aip.corrpro.com/scanlineReport',
	scanlineReportV2: 'https://cyient-api.aip.corrpro.com/scanline-report-v2',
	scanlineReportV2WS: 'wss://cyient-ws.aip.corrpro.com/scanline-report-v2',
	scanlineTags: 'https://cyient-api.aip.corrpro.com/scanline-tags',
	templateViewer:
		'https://cyient-api.aip.corrpro.com/internal-template-viewer-2',
	templates: 'https://cyient-api.aip.corrpro.com/templates',
	liveline: 'https://cyient-api.aip.corrpro.com/liveline',
	settings: 'https://cyient-api.aip.corrpro.com/settings',
	uploaderbucket: 'aegion-aip-cyient',
	uploderbucketbasepath: 'https://cyient-api.aip.corrpro.com/uploader',
	uploaderwebsocket: 'wss://cyient-ws.aip.corrpro.com/uploader',
	uploaderbucketaccessurl: 'https://cyient-api.aip.corrpro.com/uploader/access',
	uploaderfilesubmiturl:
		'https://cyient-api.aip.corrpro.com/cisview-extended/postUploaderSubmit',
	parseFile: 'https://cyient-api.aip.corrpro.com/parse-file',
	parseFileWS: 'wss://cyient-ws.aip.corrpro.com/parse-file',
	mlServerApi: 'https://ml-cyient.aip.corrpro.com/',
	mlServerLambdaApi: 'https://cyient-api.aip.corrpro.com/ml/',
	downloadReport: 'https://cyient-api.aip.corrpro.com/dataprocessor-report',
	notifications: 'https://cyient-api.aip.corrpro.com/aip-notifications',
	viewAs: 'https://cyient-api.aip.corrpro.com/view-as',
	poolid: 'cognito-idp.us-west-2.amazonaws.com/us-west-2_SKDV5WDks',
	poolidshort: 'us-east-2_tZgldPDjz',
	identifypoolid: 'us-west-2:64830314-b17d-483c-8a7f-0b3c84bf052c',
	clientid: '7njboofiifrr33cnj9pe08sth9',
	bucket: 'aegion-aip-cyient-nonversioned',
	versionedBucket: 'aegion-aip-cyient',
	dataIntegrity: 'https://cyient-api.aip.corrpro.com/dataIntegrity'
};

export default aipurls;

export { URL, REGION };
