// not working need more time to figure out why

import AWS from 'aws-sdk';

import { AuthenticationHelper, DateHelper } from 'amazon-cognito-identity-js';
import {
	SRPClient,
	calculateSignature,
	getNowString
} from 'amazon-user-pool-srp-client';
import { Sha256 } from '@aws-crypto/sha256-js';

import getAipUrls, { getRegion } from '../../../config/getAipUrls';

const AIP_URLS = getAipUrls();
const REGION = getRegion();

const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider({
	apiVersion: '2016-04-18',
	region: REGION
});

const authenticationHelper = new AuthenticationHelper(AIP_URLS.poolidshort);
const dateHelper = new DateHelper();
const srp = new SRPClient(AIP_URLS.poolidshort);

const getBigInt = () => {
	return new Promise((resolve, reject) => {
		authenticationHelper.getLargeAValue(async (errOnAValue, aValue) => {
			if (errOnAValue) {
				reject(errOnAValue);
				return;
			}
			resolve(aValue);
		});
	});
};

const getInitSRPA = async () => {
	const SRP_A = srp.calculateA();
	return SRP_A;
	const bigInt = await getBigInt();
	return bigInt.toString(16);
};

const getPasswordVerifier = async user => {
	const initSRPA = await getInitSRPA();
	const params = {
		AuthFlow: 'CUSTOM_AUTH',
		AuthParameters: {
			SRP_A: initSRPA,
			USERNAME: user,
			CHALLENGE_NAME: 'SRP_A'
		},
		ClientId: AIP_URLS.clientid
	};

	const res = await cognitoidentityserviceprovider
		.initiateAuth(params)
		.promise();
	return res;
};

const getHashedPassword = async (data, password) => {
	const challengeParameters = data.ChallengeParameters;
	const bigInt = authenticationHelper.generateRandomSmallA();
	const serverBValue = new bigInt.constructor(challengeParameters.SRP_B, 16);

	const salt = new bigInt.constructor(challengeParameters.SALT, 16);
	return new Promise((resolve, reject) => {
		const hkdf2 = srp.getPasswordAuthenticationKey(
			challengeParameters.USER_ID_FOR_SRP,
			password,
			challengeParameters.SRP_B,
			challengeParameters.SALT
		);
		const dateNow = dateHelper.getNowString();
		const signatureString = calculateSignature(
			hkdf2,
			AIP_URLS.poolidshort,
			challengeParameters.USER_ID_FOR_SRP,
			challengeParameters.SECRET_BLOCK,
			dateNow
		);
		resolve({ dateNow, signatureString });
		return;
		// eslint-disable-next-line no-unreachable
		authenticationHelper.getPasswordAuthenticationKey(
			challengeParameters.USER_ID_FOR_SRP,
			password,
			serverBValue,
			salt,
			(errOnHkdf, hkdf) => {
				if (errOnHkdf) {
					reject(errOnHkdf);
					return;
				}
				// console.log(hkdf, hkdf2);
				const concatBuffer = Buffer.concat([
					Buffer.from(AIP_URLS.poolidshort, 'utf8'),
					Buffer.from(challengeParameters.USER_ID_FOR_SRP, 'utf8'),
					Buffer.from(challengeParameters.SECRET_BLOCK, 'base64'),
					Buffer.from(dateNow, 'utf8')
				]);
				const awsCryptoHash = new Sha256(hkdf);
				awsCryptoHash.update(concatBuffer);

				const resultFromAWSCrypto = awsCryptoHash.digestSync();
				const signatureString = Buffer.from(resultFromAWSCrypto).toString(
					'base64'
				);
				resolve({ dateNow, signatureString });
			}
		);
	});
};

const verifyPassword = async (data, password) => {
	const { dateNow, signatureString } = await getHashedPassword(data, password);
	const challengeParameters = data.ChallengeParameters;

	const challengeResponses = {};

	challengeResponses.USERNAME = challengeParameters.USER_ID_FOR_SRP;
	challengeResponses.PASSWORD_CLAIM_SECRET_BLOCK =
		challengeParameters.SECRET_BLOCK;
	challengeResponses.TIMESTAMP = dateNow;
	challengeResponses.PASSWORD_CLAIM_SIGNATURE = signatureString;

	const params = {
		ChallengeName: data.ChallengeName,
		ChallengeResponses: challengeResponses,
		Session: data.Session,
		ClientId: AIP_URLS.clientid
	};

	const res = await cognitoidentityserviceprovider
		.respondToAuthChallenge(params)
		.promise();

	return res;
};

export default async (user, password) => {
	const passwordVerifier = await getPasswordVerifier(user);
	const res = await verifyPassword(passwordVerifier, password);
};
