/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-named-as-default-member */
import { createSelector } from 'reselect';
import _ from 'lodash';

import { ROOT_SLICE } from './constants';

import COG_CUSTOMERS from '../Sync/CogCustomers/selectors';
import DDB_CUSTOMERS from '../Sync/DdbCustomers/selectors';
import PSQL_CUSTOMERS from '../Sync/PsqlCustomers/selectors';
import AVEFS_CUSTOMERS from '../Sync/AvEfsCustomers/selectors';

import DDB_ENTITIES from '../Sync/DdbCustomersEntities/selectors';
import PSQL_ENTITIES from '../Sync/PsqlCustomersEntities/selectors';
import PSQL_APPLICATIONS from '../Sync/PsqlCustomersApplications/selectors';
import { selectAvMatchTermMap } from '../Sync/DdbCustomersSettings/selectors';

export const selectRootSlice = stateAdmin => stateAdmin[ROOT_SLICE];

export const selectCustomers = createSelector(
	[
		COG_CUSTOMERS.selectData,
		DDB_CUSTOMERS.selectData,
		PSQL_CUSTOMERS.selectData,
		AVEFS_CUSTOMERS.selectData
	],
	(
		cogCustomers = [],
		ddbCustomers = [],
		psqlCustomers = [],
		avEfsCustomers = []
	) => {
		return [
			...new Set([
				...cogCustomers.map(c => c.name),
				...ddbCustomers.map(c => c.name),
				...psqlCustomers.map(c => c.name),
				...avEfsCustomers.map(c => c.name)
			])
		].sort();
	}
);

export const selectIsLoadingCustomers = createSelector(
	[
		COG_CUSTOMERS.selectIsLoading,
		DDB_CUSTOMERS.selectIsLoading,
		PSQL_CUSTOMERS.selectIsLoading,
		AVEFS_CUSTOMERS.selectIsLoading,

		DDB_ENTITIES.selectIsLoading,
		PSQL_ENTITIES.selectIsLoading,

		PSQL_APPLICATIONS.selectIsLoading
	],
	(a, b, c, d, e, f, g) => {
		return a || b || c || d || e || f || g;
	}
);

export const selectIsLoadedCustomers = createSelector(
	[
		COG_CUSTOMERS.selectIsLoaded,
		DDB_CUSTOMERS.selectIsLoaded,
		PSQL_CUSTOMERS.selectIsLoaded,
		AVEFS_CUSTOMERS.selectIsLoaded,

		DDB_ENTITIES.selectIsLoaded,
		PSQL_ENTITIES.selectIsLoaded,

		PSQL_APPLICATIONS.selectIsLoaded
	],
	(a, b, c, d, e, f, g) => {
		return a && b && c && d && e && f && g;
	}
);

export const selectIsSavingEditedCustomer = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { isSavingEditedCustomer } = slice;
	return isSavingEditedCustomer;
};

export const selectIsDeletingCustomer = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { isDeletingCustomer } = slice;
	return isDeletingCustomer;
};

export const selectSelectedCustomer = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { selectedCustomer } = slice;
	return selectedCustomer;
};

export const selectSelectedCustomerChangesClone = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { selectedCustomerChangesClone } = slice;
	return selectedCustomerChangesClone;
};

export const selectCustomerInCog = (stateAdmin, { customer = '' } = {}) => {
	const boolMap = COG_CUSTOMERS.selectBoolMap(stateAdmin);
	return boolMap[customer];
};

export const selectCustomerInDdb = (stateAdmin, { customer = '' } = {}) => {
	const boolMap = DDB_CUSTOMERS.selectBoolMap(stateAdmin);
	return boolMap[customer];
};

export const selectCustomerInPsql = (stateAdmin, { customer = '' } = {}) => {
	const boolMap = PSQL_CUSTOMERS.selectBoolMap(stateAdmin);
	return boolMap[customer];
};

export const selectCustomerInAvEfs = (stateAdmin, { customer = '' } = {}) => {
	const boolMap = AVEFS_CUSTOMERS.selectBoolMap(stateAdmin);
	return boolMap[customer];
};

export const selectCombinedEntitiesData = createSelector(
	DDB_ENTITIES.selectData,
	PSQL_ENTITIES.selectData,
	(ddbEntities = {}, psqlEntities = {}) => {
		const entities = _.cloneDeep(ddbEntities);
		const psqlKeys = Object.keys(psqlEntities);

		return psqlKeys.reduce((acc, k) => {
			if (psqlEntities[k] && psqlEntities[k].length) {
				acc[k] = [
					...new Set([...(entities[k] || []), ...(psqlEntities[k] || [])])
				].sort();
			}
			return acc;
		}, entities);
	}
);

export const selectEntityMismatch = (stateAdmin, { customer = '' } = {}) => {
	const ddb = {
		data: DDB_ENTITIES.selectData(stateAdmin)[customer] || [],
		boolMap: DDB_ENTITIES.selectBoolMap(stateAdmin)[customer] || {}
	};

	const psql = {
		data: PSQL_ENTITIES.selectData(stateAdmin)[customer] || [],
		boolMap: PSQL_ENTITIES.selectBoolMap(stateAdmin)[customer] || {}
	};

	const missingDDB = ddb.data.find(e => !psql.boolMap[e]);
	const missingPSQL = psql.data.find(e => !ddb.boolMap[e]);

	return !!(missingDDB || missingPSQL);
};

export const selectIsApplicationEnabled = (stateAdmin, customer, appKey) => {
	const psql = {
		boolMap: PSQL_APPLICATIONS.selectBoolMap(stateAdmin)[customer] || {}
	};
	return psql.boolMap[appKey];
};

export const selectHasChanges = createSelector(
	selectSelectedCustomer,
	selectSelectedCustomerChangesClone,
	(selectedCustomer, selectedClone) => {
		const customer = _.cloneDeep(selectedCustomer) || {};

		const clone = _.cloneDeep(selectedClone) || {};

		return customer && clone && !_.isEqual(customer, clone);
	}
);

export const selectHasAvMatchTermMismatch = (stateAdmin, { customer = '' }) => {
	const isInAvEfs = selectCustomerInAvEfs(stateAdmin, { customer });
	const isAVEnabled = selectIsApplicationEnabled(
		stateAdmin,
		customer,
		'ASSETVIEW®'
	);
	const matchTermMap = AVEFS_CUSTOMERS.selectMatchTermMap(stateAdmin);
	const ddbAvMatchTermMap = selectAvMatchTermMap(stateAdmin);
	const matchTermMismatch =
		matchTermMap[customer] !== ddbAvMatchTermMap[customer];
	return (
		(isAVEnabled && !isInAvEfs) ||
		(!isAVEnabled && isInAvEfs) ||
		(isAVEnabled && matchTermMismatch)
	);
};

export const selectIsOutOfSync = (stateAdmin, { customer = '' } = {}) => {
	const isInCog = selectCustomerInCog(stateAdmin, { customer });
	const isInDdb = selectCustomerInDdb(stateAdmin, { customer });
	const isInPsql = selectCustomerInPsql(stateAdmin, { customer });
	const hasAvMatchTermMismatch = selectHasAvMatchTermMismatch(stateAdmin, {
		customer
	});
	const hasEntityMismatch = selectEntityMismatch(stateAdmin, { customer });
	return (
		!isInCog ||
		!isInDdb ||
		!isInPsql ||
		hasAvMatchTermMismatch ||
		hasEntityMismatch
	);
};

export const selectErrorMessageSaveEditedCustomer = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { errorMessageSaveEditedCustomer } = slice;
	return errorMessageSaveEditedCustomer;
};

export const selectShowConfirmation = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { showConfirmation } = slice;
	return showConfirmation;
};

export const selectConfirmationMessageKey = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { confirmationMessageKey } = slice;
	return confirmationMessageKey;
};

export const selectSelectedCustomerChangesCloneExistingEntities = createSelector(
	[selectSelectedCustomerChangesClone],
	clone => {
		return (clone?.entities || []).filter(e => !e.isNew);
	}
);
export const selectSelectedCustomerChangesCloneNewEntities = createSelector(
	[selectSelectedCustomerChangesClone],
	clone => {
		return (clone?.entities || []).filter(e => !!e.isNew);
	}
);
