import aipurls, { URL, REGION } from './configs.urls';

const getAipUrls = () => {
	return aipurls;
};

function getURL(path) {
	return `${URL}/${path}`;
}

function getRegion() {
	return REGION || 'us-west-2';
}

export default getAipUrls;

export { getURL, getRegion };
