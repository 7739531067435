import {
	CognitoUserPool,
	CognitoUser,
	AuthenticationDetails
} from 'amazon-cognito-identity-js';

import getAipUrls from '../../../config/getAipUrls';
import { cGetSession } from './mfaCookies';

const AIP_URLS = getAipUrls();

const poolData = {
	UserPoolId: AIP_URLS.poolidshort, // Your user pool id here
	ClientId: AIP_URLS.clientid // Your client id here
};
const userPool = new CognitoUserPool(poolData);

export default (user, password) => {
	const userData = {
		Username: user,
		Pool: userPool
	};

	const cognitoUser = new CognitoUser(userData);
	cognitoUser.setAuthenticationFlowType('CUSTOM_AUTH');

	const authenticationData = {
		Username: user,
		Password: password,
		AuthParameters: { CHALLENGE_NAME: 'SRP_A' }
	};

	const authenticationDetails = new AuthenticationDetails(authenticationData);

	return new Promise((resolve, reject) => {
		cognitoUser.authenticateUser(authenticationDetails, {
			onSuccess(result) {
				resolve({ AuthenticationResult: result });
				// User authentication was successful
			},
			onFailure(err) {
				reject(err);
				// User authentication was not successful
			},
			customChallenge(challengeParameters) {
				resolve({
					ChallengeName: 'CUSTOM_CHALLENGE',
					ChallengeParameters: { USERNAME: user, Session: cognitoUser.Session }
				});
				// User authentication depends on challenge response
				// const challengeResponses = 'challenge-answer';
				// cognitoUser.sendCustomChallengeAnswer(challengeResponses, this);
			},
			newPasswordRequired(userAttributes, requiredAttributes) {
				resolve({
					action: 'NEW_PASSWORD',
					sessionUserAttributes: userAttributes,
					cognitoUser
				});
			}
		});
	});
};

const setNewPassword = (user, newPassword) => {
	const userData = {
		Username: user,
		Pool: userPool
	};

	const cognitoUser = new CognitoUser(userData);
	cognitoUser.Session = cGetSession();
	return new Promise((resolve, reject) => {
		cognitoUser.completeNewPasswordChallenge(newPassword, [], {
			onSuccess(result) {
				resolve({ AuthenticationResult: result });
				// User authentication was successful
			},
			onFailure(err) {
				reject(err);
				// User authentication was not successful
			}
		});
	});
};

export { setNewPassword };
