/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
import { createPromiseRequest } from './util/createPromiseRequest';
import { hasAccessToken } from '../../scanline/utils/token';

export const getCustomers = () => {
	const url = '/customers';

	if (hasAccessToken()) {
		const fetchData = createPromiseRequest({
			url,
			method: 'GET'
		});

		return fetchData();
	}
	return Promise.reject(new Error('Missing Token'));
};

export const updateCustomer = ({ customer } = {}) => {
	if (!customer || !customer.id) {
		return Promise.reject(
			new Error(
				'Invalid customer object passed to api/customers .updateCustomer()'
			)
		);
	}

	const postAppInvite = createPromiseRequest({
		url: '/customers',
		method: 'POST',
		data: customer
	});

	return postAppInvite();
};

export const deleteCustomer = ({ customer } = {}) => {
	if (!customer || !customer.id) {
		return Promise.reject(
			new Error(
				'Invalid customer object passed to api/customers .deleteCustomer()'
			)
		);
	}

	const postAppInvite = createPromiseRequest({
		url: '/customers',
		method: 'DELETE',
		data: customer
	});

	return postAppInvite();
};

export const insertCustomer = ({ customer } = {}) => {
	if (!customer || !customer.name) {
		return Promise.reject(
			new Error(
				'Invalid customer object passed to api/customers .updateCustomer()'
			)
		);
	}

	const postAppInvite = createPromiseRequest({
		url: '/customers',
		method: 'PUT',
		data: customer
	});

	return postAppInvite();
};

// CUSTOMERS V2
export const insertCustomerV2 = ({ customer } = {}) => {
	if (!customer || !customer.name) {
		return Promise.reject(
			new Error(
				'Invalid customer object passed to api/customers .insertCustomerV2()'
			)
		);
	}

	const httpInsertCustomer = createPromiseRequest({
		url: '/customersv2',
		method: 'POST',
		data: { customer }
	});

	return httpInsertCustomer();
};

export const updateCustomerV2 = ({ customer } = {}) => {
	if (!customer || !customer.name) {
		return Promise.reject(
			new Error(
				'Invalid customer object passed to api/customers .updateCustomerV2()'
			)
		);
	}

	const httpUpdateCustomer = createPromiseRequest({
		url: '/customersv2',
		method: 'PUT',
		data: { customer }
	});

	return httpUpdateCustomer();
};

export const saveCustomerV2 = ({ customer } = {}) => {
	if (!customer || !customer.name) {
		return Promise.reject(
			new Error(
				'Invalid customer object passed to api/customers/save .saveCustomerV2()'
			)
		);
	}

	const httpUpdateCustomer = createPromiseRequest({
		url: '/customersv2/save',
		method: 'PUT',
		data: { customer }
	});

	return httpUpdateCustomer();
};

export const deleteCustomerV2 = ({ customer } = {}) => {
	if (!customer || !customer.name) {
		return Promise.reject(
			new Error(
				'Invalid customer object passed to api/customers .deleteCustomerV2()'
			)
		);
	}

	const httpDeleteCustomer = createPromiseRequest({
		url: '/customersv2',
		method: 'DELETE',
		data: { customer }
	});

	return httpDeleteCustomer();
};

export const DDBGET = ({ customer } = {}) => {
	if (!customer || !customer.name) {
		return Promise.reject(
			new Error('Invalid customer object passed to api/customers .DDBGET()')
		);
	}

	const httpDeleteCustomer = createPromiseRequest({
		url: '/customersv2/DDBGET',
		method: 'POST',
		data: { customer }
	});

	return httpDeleteCustomer();
};

export const listCogCustomers = () => {
	const url = '/cog/customers';

	if (hasAccessToken()) {
		const fetch = createPromiseRequest({
			url,
			method: 'GET'
		});

		return fetch();
	}
	return Promise.reject(new Error('Missing token'));
};

export const listDdbCustomers = () => {
	const url = '/doc/customers';

	if (hasAccessToken()) {
		const fetch = createPromiseRequest({
			url,
			method: 'GET'
		});

		return fetch();
	}
	return Promise.reject(new Error('Missing token'));
};

export const listPsqlCustomersV2 = () => {
	const url = '/rel/customers';

	if (hasAccessToken()) {
		const fetch = createPromiseRequest({
			url,
			method: 'GET'
		});

		return fetch();
	}
	return Promise.reject(new Error('Missing token'));
};

export const listAvEfsCustomers = () => {
	const url = '/av-efs/customers';

	if (hasAccessToken()) {
		const fetch = createPromiseRequest({
			url,
			method: 'GET'
		});

		return fetch();
	}
	return Promise.reject(new Error('Missing token'));
};
