import {
	createCookie,
	getCookie,
	deleteCookie
} from '../../../scanline/utils/cookies';

const EXPIRE_MINS = 5;

const KEY_MFA_USER = 'AIM-MFA-USERNAME';
const KEY_MFA_SESSION = 'AIM-MFA-SESSION';
const KEY_VERIFICATION_CODE = 'AIM-VERIFICATION-CODE';

const getAipCookieConsent = () => {
	const name = `AipCookieConsent=`;
	const decodedCookie = decodeURIComponent(document.cookie);
	const ca = decodedCookie.split(';');
	for (let i = 0; i < ca.length; i += 1) {
		let c = ca[i];
		while (c.charAt(0) === ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) === 0) {
			return c.substring(name.length, c.length);
		}
	}
	return '';
};

const createExpInMin = minutes => {
	const date = new Date();
	date.setMinutes(date.getMinutes() + minutes);
	return date;
};

// USER
export const cSetUser = username => {
	if (getAipCookieConsent()) {
		createCookie(KEY_MFA_USER, username, createExpInMin(EXPIRE_MINS), '/');
	}
};

export const cGetUser = () => {
	return getAipCookieConsent() ? getCookie(KEY_MFA_USER) : undefined;
};

export const cDeleteUser = () => deleteCookie(KEY_MFA_USER, '/');

export const cSetSession = session => {
	if (getAipCookieConsent()) {
		createCookie(KEY_MFA_SESSION, session, createExpInMin(EXPIRE_MINS), '/');
	}
};

export const cGetSession = () => {
	return getAipCookieConsent() ? getCookie(KEY_MFA_SESSION) : undefined;
};

export const cDeleteSession = () => deleteCookie(KEY_MFA_SESSION, '/');

export const cSetVerificationCode = session => {
	if (getAipCookieConsent()) {
		createCookie(
			KEY_VERIFICATION_CODE,
			session,
			createExpInMin(EXPIRE_MINS),
			'/'
		);
	}
};

export const cGetVerificationCode = () => {
	return getAipCookieConsent() ? getCookie(KEY_VERIFICATION_CODE) : undefined;
};

export const cDeleteVerificationCode = () =>
	deleteCookie(KEY_VERIFICATION_CODE, '/');
